<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Reportes</h4>
            <div class="small text-muted">Administración de todos los reportes</div>
          </b-col>
          <b-col>            
            <b-icon icon="question-circle-fill" class="h3 pull-right mt-2 module-help-information" v-b-popover.hover="help"></b-icon>            
          </b-col>           
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>
            <b-col lg="4">
              <Widget :configWidget="configWidget" reference="indicator_total_reports" :hasAccess="configWidget.elements.indicatorTotalReports" />                            
            </b-col>
            <b-col lg="4"> 
              <Widget :configWidget="configWidget" reference="list_unconfigured_reports" :hasAccess="configWidget.elements.listUnconfiguredReports" />
            </b-col>
          </b-row>
        </b-col>  
        <b-col lg="3">

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openReport()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Diseño</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Diseño de personalizado de reportes
              </p>
            </b-list-group-item>
          </b-list-group>

        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.REPORTES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.REPORTES,
          profile_id: Profiles.PERSONAL,
          elements: {             
            indicatorTotalReports: true,        
            listUnconfiguredReports: true,     
          }
        },
        primaryColor: '',
        help: { 
          title:'¿Cómo funciona este módulo?',
          content: () => { 
            return `Desde este módulo se podrá configurar cada reporte que el sistema necesite para cada módulo.`
          },            
          html: true  
        }      
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.indicatorTotalReports = Helper.hasAccessWidget(this.configWidget, 'indicator_total_reports')
      this.configWidget.elements.listUnconfiguredReports = Helper.hasAccessWidget(this.configWidget, 'list_unconfigured_reports')
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    computed: {
        
    },       
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      
      openReport() {
        this.$router.push({ name: 'ReportsStaffDesign' })
      },
    }    
   
  }
</script>